<div
  class="h-screen absolute w-screen login-page flex items-center justify-center"
>
  <input
    placeholder="◦◦◦◦◦"
    (input)="tryPass(input.value)"
    #input
    type="text"
    class="input rounded shadow"
    autofocus
  />
</div>
