<app-login
  *ngIf="!connected; else appTemplate"
  (connect)="connected = true"
  [code]="code"
></app-login>

<ng-template #appTemplate>
  <div
    (window:resize)="calibrationS.refreshSize($event)"
    class="text-center header-container"
  >
    <app-header
      (opOpenCalibration)="openCalibration()"
      [opTitle]="'Mallet versus Tablet'"
      [opOpenedCalibration]="openedCalibration"
      [opText]="text"
    ></app-header>
  </div>
  <div class="w-lg mx-auto max-w-full">
    <app-image-optometrie-list
      (opOpenOptoImage)="openedOptoImage = $event"
      [opOptometricImages]="images"
    ></app-image-optometrie-list>
  </div>

  <app-image-optometrie-full-screen
    (opHideImage)="openedOptoImage = null"
    [opShowOptoImage]="openedOptoImage"
    [opPxToCm]="calibrationS.pxToCm"
  ></app-image-optometrie-full-screen>

  <app-calibration-full-screen
    [opRefreshSize]="calibrationS.refreshSize$ | async"
    [opShowCalibration]="openedCalibration"
    [opOldPxToCmRatio]="calibrationS.pxToCm"
    (opHideCalibration)="openedCalibration = false"
    (opPxToCmRatio)="calibrationS.updateRatio($event)"
  >
  </app-calibration-full-screen>
</ng-template>
