<div
  (click)="emitOpenImage()"
  class="img-container rounded-lg shadow-lg max-w-full flex justify-center"
  [style.background-color]="'#25282c'"
>
  <div
    class="img-background shadow"
    [style.background-image]="'url(' + preview + ')'"
  ></div>
  <h3 class="bg-white text-primary shadow card-title">
    {{ optometricImage.title }}
  </h3>
</div>
