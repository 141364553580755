export const homeText = `
### Critères d'inclusion

- Être âgé de minimum 18 ans et de maximum 65 ans
- Ne pas avoir connaissance d'être enceinte
- Être non strabique au cover test (VL et VP)
- Acuité visuelle :
- Capable de voir l'acuité N12 (0.20 unité LOG) de manière monoculaire pour chaque œil.
- L'AV de l'œil droit doit différer de moins de 0.1 Log de celle de l'œil gauche

### Conditions du testing

- Le testing doit se faire dans une pièce normalement éclairée
- Le sujet porte sa compensation VP habituelle
- Les tests se font à 40 cm

### Phraséologie pour le testing horizontal :

Pouvez-vous lire le mot OXO ou UHU avec les deux yeux ?

Voyez-vous les deux lignes vertes OU la ligne rouge et la ligne verte simultanément, une au-dessus et en dessous du X ou du H et les deux lignes sont-elles exactement alignées l'une en dessous de l'autre ?

<span class='text-danger'>Si pas, abandon</span>

#### Mise en place du dissociateur

Les deux lignes vertes ou la ligne rouge et la ligne verte (une au-dessus et une en-dessous du X ou du H) sont-elles présentes en permanence  ou sont-elles présentes simultanément par moment ?

<span class='text-danger'>Si pas, abandon</span>

Les deux lignes sont-elles exactement alignées ?

<span class='text-danger'>Si non</span>: Une ligne ou les deux lignes se déplacent-elles dans une direction par rapport au centre du X ?

Une seule ligne ou les deux ?

Est-ce que la ou les lignes se déplace(nt) vers la gauche, la droite ou pareillement des deux côtés ?

<span class='text-success'>(si égalité cela peut être une instabilité. Tester 0.5∆ out et 0.5 ∆ In)</span>

Si plus dans un sens, interposer des prismes Out ou In par ∆ devant l'œil ou  les yeux jusqu' alignement

### Phraséologie pour le testing vertical:

Pouvez-vous lire le mot OXO ou UHU avec les deux yeux ?

Voyez-vous les deux lignes vertes OU la ligne rouge et la ligne verte simultanément, à gauche et à droite du X ou du H et les deux lignes sont-elles exactement alignées l'une à côté de l'autre ?

#### mise en place du dissociateur

Les deux lignes vertes ou la ligne rouge et la ligne verte (de chaque côté du X ou du H) sont-elles présentes en permanence  ou sont-elles présentes simultanément par moment ?

<span class='text-danger'>Si pas, abandon</span>

Les deux lignes sont-elles exactement alignées ?

<span class='text-danger'>Si non</span>: Une ligne ou les deux lignes se déplacent-elles dans une direction par rapport au centre du X ?

Une seule ligne ou les deux ?

Est-ce que la ou les lignes se déplace(nt) vers le haut, le bas ou pareillement des deux côtés ?

<span class='text-success'>(si égalité cela peut être une instabilité. Tester 0.5∆ up et 0.5 ∆ dwn)</span>

Si plus dans un sens, interposer des prismes Up ou Dwn par ∆ devant l'œil ou  les yeux jusqu'alignement

**Lors du deuxième test, il ne sera pas nécessaire de vérifier l'alignement initial.**
`;
