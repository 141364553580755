<div class="bg-light shadow fixed top-0 z-100 w-full header-bar">
  <div class="flex items-center">
    <h1>{{ title }}</h1>
  </div>
  <div class="absolute calibration-container">
    <app-calibration
      (opOpenCalibration)="openCalibration.emit($event)"
      [opOpenedCalibration]="openedCalibration"
    ></app-calibration>
  </div>
</div>

<div class="text container px-16px">
  <markdown [data]="text"></markdown>
</div>

<div class="split-line my-6 mx-auto" *ngIf="text"></div>
